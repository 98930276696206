import { AppEpic } from '../../store/root-epic';
import { signUp } from './SignUp.slice';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { from, of, throwError } from 'rxjs';
import { auth, mapUser } from '../reusable/slices/auth.slice';

export const signUpEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(signUp.request.match),
    mergeMap(({ payload: { email, password } }) =>
      from(firebase.signUp(email, password)).pipe(
        switchMap(({ user }) => (user ? of(auth.setUser(mapUser(user))) : throwError('user is null after creation'))),
        catchError((error) => of(signUp.failure(error.toString()))),
      ),
    ),
  );
