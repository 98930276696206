import { deleteRecording, searchRecordingIds, setTranscriptToElastic } from './search.service';
import { defer, merge, Observable, of, throwError } from 'rxjs';
import { ActionsObservable } from 'redux-observable';
import { AnyAction } from '@reduxjs/toolkit';
import { AjaxError } from 'rxjs/ajax';
import { filter, mergeMapTo, take, takeUntil } from 'rxjs/operators';
import { auth } from '../components/reusable/slices/auth.slice';
import { getFullConfiguration } from './configuration.service';

const apiRoot = `${process.env.REACT_APP_API_URL}/api`;

const getHeaders = () => ({
  Authorization: `${localStorage.getItem('access-token')}`,
});

export const refreshAccessTokenAndRetry = <A>(action$: ActionsObservable<AnyAction>, source: Observable<A>) =>
  merge(
    action$.pipe(
      filter(auth.refreshAccessTokenSuccess.match),
      takeUntil(action$.pipe(filter(auth.clear.match))),
      take(1),
      mergeMapTo(source),
    ),
    of(auth.refreshAccessTokenRequest()),
  );

export const handleAjaxError = (
  action$: ActionsObservable<AnyAction>,
  errorActionCreator?: (error: unknown) => AnyAction,
) => <A>(error: unknown, source: Observable<A>) => {
  if (error instanceof AjaxError && error.status === 401) {
    return refreshAccessTokenAndRetry(action$, source);
  } else {
    return errorActionCreator ? of(errorActionCreator(error)) : throwError(error);
  }
};

export default {
  search: {
    recordingIds: (search: string): ReturnType<typeof searchRecordingIds> =>
      defer(() => searchRecordingIds(apiRoot, getHeaders(), search)),
    deleteRecording: (id: string): ReturnType<typeof deleteRecording> =>
      defer(() => deleteRecording(apiRoot, getHeaders(), id)),
    setTranscriptToElastic: (transcript: string, id: string): ReturnType<typeof setTranscriptToElastic> =>
      defer(() => setTranscriptToElastic(apiRoot, getHeaders(), transcript, id)),
  },
  configuration: {
    full: (): ReturnType<typeof getFullConfiguration> => defer(() => getFullConfiguration(apiRoot, getHeaders())),
  },
};
