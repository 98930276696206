import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RecordingEvent = {
  id: string;
  summary: string;
  start?: {
    datetime?: any;
    date?: any;
  };
};

type EventsState = {
  signedToGAPI: boolean;
  recordingEvent?: RecordingEvent;
};

const initialState: EventsState = {
  signedToGAPI: false,
  recordingEvent: undefined,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    setSignedToGAPI: (state, action: PayloadAction<boolean>) => {
      state.signedToGAPI = action.payload;
    },
    setRecordingEvent: (state, action: PayloadAction<RecordingEvent>) => {
      state.recordingEvent = action.payload;
    },
    clearRecordingEvent: (state) => {
      state.recordingEvent = undefined;
    },
  },
});

export const events = eventsSlice.actions;

export default eventsSlice.reducer;
