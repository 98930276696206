import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const searchFailure = createAction<string>('header/searchFailure');

type HeaderState = {
  searchValue: string;
};

const initialState: HeaderState = {
  searchValue: '',
};

const headerSlice = createSlice({
  name: 'header',
  initialState: initialState,
  reducers: {
    updateSearch: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const header = {
  searchFailure,
  ...headerSlice.actions,
};

export default headerSlice.reducer;
