import { AppEpic } from '../../store/root-epic';
import { debounceTime, filter, map } from 'rxjs/operators';
import { header } from './Header.slice';
import { recordingsList } from '../RecordingsList/RecordingsList.slice';

export const searchEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(header.updateSearch.match),
    map(({ payload }) => payload.trim()),
    debounceTime(500),
    map((searchValue) => (searchValue ? recordingsList.bySearchRequest(searchValue) : recordingsList.allRequest())),
  );
