import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecordingInfo } from '../Home/Home.slice';

type RecordingsListState = {
  recordings: RecordingInfo[];
  error?: string;
  isLoading: boolean;
};

const initialState: RecordingsListState = {
  recordings: [],
  error: undefined,
  isLoading: false,
};

const recordingsListSlice = createSlice({
  name: 'recordings-list',
  initialState: initialState,
  reducers: {
    request: (state) => {
      state.error = undefined;
      state.isLoading = true;
    },
    success: (state, action: PayloadAction<Array<RecordingInfo>>) => {
      state.recordings = action.payload;
      state.isLoading = false;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    deleteById: (state, action: PayloadAction<string>) => {
      const index = state.recordings.findIndex((recording) => recording.id === action.payload);
      if (index !== -1) {
        state.recordings.splice(index, 1);
      }
    },
  },
});

export const recordingsList = {
  allRequest: createAction('recordings-list/allRequest'),
  bySearchRequest: createAction<string>('recordings-list/bySearchRequest'),
  ...recordingsListSlice.actions,
};

export default recordingsListSlice.reducer;
