import api from './api.service';
import * as firebase from './firebase.service';
import * as socket from './socket.service';

const services = {
  firebase: firebase,
  api: api,
  socket: socket,
};

export type AppServices = typeof services;

export default services;
