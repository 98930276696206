import { combineReducers } from '@reduxjs/toolkit';
import signUpReducer from '../components/SignUp/SignUp.slice';
import authReducer from '../components/reusable/slices/auth.slice';
import signInReducer from '../components/SignIn/SignIn.slice';
import homeReducer from '../components/Home/Home.slice';
import configurationInfoReducer from '../components/ConfigurationInfo/ConfigurationInfo.slice';
import eventsReducer from '../components/Events/Events.slice';
import headerReducer from '../components/Header/Header.slice';
import createRecordingReducer from '../components/CreateRecording/CreateRecording.slice';

const rootReducer = combineReducers({
  signUp: signUpReducer,
  auth: authReducer,
  signIn: signInReducer,
  home: homeReducer,
  configurationInfo: configurationInfoReducer,
  events: eventsReducer,
  header: headerReducer,
  createRecording: createRecordingReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
