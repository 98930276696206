import { AppEpic } from '../../../store/root-epic';
import { catchError, delayWhen, filter, ignoreElements, switchMap, tap } from 'rxjs/operators';
import { auth } from '../slices/auth.slice';
import { from, of, throwError } from 'rxjs';
import * as E from 'fp-ts/Either';
import { flow } from 'fp-ts/function';

export const setUserEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(auth.setUser.match),
    tap(({ payload: user }) => localStorage.setItem('user', JSON.stringify(user))),
    delayWhen(({ payload: { uid } }) => firebase.maintainUserMeetings(uid)),
    ignoreElements(),
  );

export const clearAuthEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(auth.clear.match),
    tap(() => localStorage.removeItem('user')),
    tap(() => localStorage.removeItem('access-token')),
    delayWhen(() => from(firebase.signOut())),
    ignoreElements(),
  );

export const refreshAccessTokenEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(auth.refreshAccessTokenRequest.match),
    switchMap(() =>
      from(firebase.getAccessToken()()).pipe(
        switchMap(
          flow(
            E.chainFirst((accessToken) => E.of(localStorage.setItem('access-token', accessToken))),
            E.fold(throwError, (accessToken) => of(auth.refreshAccessTokenSuccess(accessToken))),
          ),
        ),
        catchError(() => of(auth.clear())),
      ),
    ),
  );
