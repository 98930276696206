import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import { pipe } from 'fp-ts/pipeable';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import { RecordingInfo } from '../components/Home/Home.slice';
import { from, Observable } from 'rxjs';
import { ajax } from 'rxjs/internal-compatibility';
import { map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

type UserCredential = firebase.auth.UserCredential;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(firebaseConfig);
const provider = new firebase.auth.GoogleAuthProvider();
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

export const signUp = (email: string, password: string): Promise<UserCredential> =>
  auth.createUserWithEmailAndPassword(email, password);

export const signInWithEmailPassword = (email: string, password: string): Promise<UserCredential> =>
  auth.signInWithEmailAndPassword(email, password);

export const signInWithGoogle = (): Promise<UserCredential> => auth.signInWithPopup(provider);

export const signOut = (): Promise<void> => auth.signOut();

export const getAccessToken = (): TE.TaskEither<Error, string> =>
  pipe(
    auth.currentUser,
    E.fromNullable(new Error('User is not present')),
    TE.fromEither,
    TE.bind('accessToken', (user) =>
      TE.tryCatch(
        () => user.getIdToken(true),
        (reason) => new Error(`${reason}`),
      ),
    ),
    TE.map(({ accessToken }) => accessToken),
  );

export const maintainUserMeetings = (userId: string): Observable<void> =>
  from(
    storage
      .ref()
      .child(userId)
      .listAll()
      .then((res) => {
        res.items.forEach((ref) => {
          firestore
            .collection('users')
            .doc(userId)
            .collection('meetings')
            .doc(ref.name)
            .get()
            .then(async (snap) => {
              if (!snap.exists) {
                await storage.ref().child(`${userId}/${ref.name}`).delete();
              }
            });
        });
      }),
  );

export const getAllRecordings = (uid: string): Observable<Array<RecordingInfo>> =>
  from(
    firestore
      .collection('users')
      .doc(uid)
      .collection('meetings')
      .orderBy('endTime', 'desc')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => {
          const item = doc.data();

          return {
            id: doc.id,
            title: item.title,
            date: item.endTime,
            words: item.words,
            translate: item.translate,
          };
        }),
      ),
  );

export const getRecordingsByIds = (uid: string, recordingIds: Array<string>): Observable<Array<RecordingInfo>> =>
  from(
    Promise.all(
      recordingIds.map((id) =>
        firestore
          .collection('users')
          .doc(uid)
          .collection('meetings')
          .doc(id)
          .get()
          .then((doc) => {
            const data = doc.data();

            return {
              id: doc.id,
              title: data?.title,
              date: data?.endTime,
              words: data?.words,
              translate: data?.translate,
            };
          }),
      ),
    ),
  );

export const getRecordingAudioUrl = (uid: string, recordingId: string): Observable<string> =>
  from(storage.ref().child(`${uid}/${recordingId}`).getDownloadURL()).pipe(
    switchMap((url) =>
      ajax({
        method: 'GET',
        responseType: 'blob',
        url: url,
      }),
    ),
    map(({ response }) => URL.createObjectURL(response)),
  );

export const deleteFileFromStorage = (uid: string, recordingId: string): Promise<unknown> =>
  storage.ref().child(`${uid}/${recordingId}`).delete();

export const deleteFile = (uid: string, recordingId: string): Observable<unknown> =>
  from(deleteFileFromStorage(uid, recordingId)).pipe(
    switchMap(() => from(firestore.collection('users').doc(uid).collection('meetings').doc(recordingId).delete())),
  );

export const getUsedMemory = (uid: string): Observable<number> =>
  from(
    storage
      .ref()
      .child(uid)
      .listAll()
      .then((listResult) => Promise.all(listResult.items.map((ref) => ref.getMetadata())))
      .then((files) => files.reduce((usedMemory, currentFile) => usedMemory + currentFile.size, 0))
      .catch((e) => {
        console.log(JSON.stringify(e));
      }),
  ).pipe(map((usedMemory: number) => usedMemory / 1000000));

export const setFileToStorage = (uid: string, file: Blob): Observable<string> => {
  const key = uuidv4();
  return from(storage.ref().child(`${uid}/${key}`).put(file)).pipe(map(() => key));
};

export const setDataToFirestoreDB = (uid: string, fileKey: string, data: any): Observable<boolean> =>
  from(
    firestore
      .collection('users')
      .doc(uid)
      .collection('meetings')
      .doc(fileKey)
      .set({
        ...data,
        endTime: firebase.firestore.Timestamp.fromDate(new Date()),
      }),
  ).pipe(map(() => true));
