import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecordingInfo } from '../Home/Home.slice';
import { History } from 'history';

type RecordingState = {
  data?: RecordingInfo & { audioUrl?: string };
  audioUrlError?: string;
  isLoadingAudioUrl: boolean;
};

const initialState: RecordingState = {
  data: undefined,
  audioUrlError: undefined,
  isLoadingAudioUrl: false,
};

const recordingSlice = createSlice({
  name: 'recording',
  initialState: initialState,
  reducers: {
    select: (state, action: PayloadAction<{ recording: RecordingInfo; history: History }>) => {
      if (state.data?.id === action.payload.recording.id) {
        return;
      }

      state.data = action.payload.recording;
    },
    deselect: (state) => {
      state.data = undefined;
    },
    audioUrlRequest: (state, _: PayloadAction<string>) => {
      state.audioUrlError = undefined;
      state.isLoadingAudioUrl = true;
    },
    audioUrlSuccess: (state, action: PayloadAction<string>) => {
      state.audioUrlError = undefined;
      state.isLoadingAudioUrl = false;

      if (state.data) {
        state.data.audioUrl = action.payload;
      }
    },
    audioUrlFailure: (state, action: PayloadAction<string>) => {
      state.audioUrlError = action.payload;
      state.isLoadingAudioUrl = false;
    },
    setTimestamp: (state, action: PayloadAction<string>) => {
      if (!state.data?.audioUrl) {
        return;
      }

      const blobWithoutTimeStamp = state.data.audioUrl.split('#t=')[0];

      state.data.audioUrl = blobWithoutTimeStamp + '#t=' + action.payload;
    },
  },
});

export const recording = recordingSlice.actions;

export default recordingSlice.reducer;
