import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientConfiguration, ServerConfiguration } from '../../services/configuration.service';

export type ConfigurationInfoState = {
  isLoading: boolean;
  error?: string;
  client?: ClientConfiguration;
  server?: ServerConfiguration;
};

const initialState: ConfigurationInfoState = {
  isLoading: false,
  client: undefined,
  server: undefined,
  error: undefined,
};

const configurationInfoSlice = createSlice({
  name: 'configuration-info',
  initialState: initialState,
  reducers: {
    request: (state) => {
      state.error = undefined;
      state.isLoading = true;
    },
    success: (state, action: PayloadAction<{ client: ClientConfiguration; server: ServerConfiguration }>) => {
      state.error = undefined;
      state.isLoading = false;
      state.client = action.payload.client;
      state.server = action.payload.server;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const configurationInfo = configurationInfoSlice.actions;

export default configurationInfoSlice.reducer;
