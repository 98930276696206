import { combineReducers, createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createRecordingConfigurationReducer from './CreateRecordingConfiguration/CreateRecordingConfiguration.slice';
import createRecordingBodyReducer from './CreateRecordingBody/CreateRecordingBody.slice';
import audioRecorderReducer from './AudioRecorder/AudioRecorder.slice';
import { SpeechEvent } from '../../services/socket.service';
import { Socket } from 'socket.io-client';

type CreateRecordingState = {
  occupiedMemory: number;
  isLoading: boolean;
  error?: string;
  title: string;
};

const initialState: CreateRecordingState = {
  occupiedMemory: 0,
  isLoading: false,
  error: undefined,
  title: '',
};

const createRecordingSlice = createSlice({
  name: 'create-recording',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    refreshOccupiedMemoryRequest: (state) => {
      state.isLoading = true;
      state.error = undefined;
    },
    refreshOccupiedMemorySuccess: (state, action: PayloadAction<number>) => {
      state.isLoading = false;
      state.error = undefined;
      state.occupiedMemory = action.payload;
    },
    refreshOccupiedMemoryFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    saveRequest: (state) => {
      state.isLoading = true;
      state.error = undefined;
    },
    saveFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    saveSuccess: (state) => {
      state.isLoading = false;
      state.error = undefined;
    },
  },
});

export const createRecording = {
  openSpeechSocket: createAction('create-recording/openSpeechSocket'),
  speechSocketReady: createAction<Socket>('create-recording/speechSocketReady'),
  closeSpeechSocket: createAction('create-recording/closeSpeechSocket'),
  processSpeechEvent: createAction<{ event: SpeechEvent; socket: Socket }>('create-recording/processSpeechEvent'),
  ...createRecordingSlice.actions,
};

export default combineReducers({
  root: createRecordingSlice.reducer,
  body: createRecordingBodyReducer,
  recorder: audioRecorderReducer,
  configuration: createRecordingConfigurationReducer,
});
