/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AppEpic } from '../../store/root-epic';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { handleAjaxError } from '../../services/api.service';
import { recordingsList } from './RecordingsList.slice';

export const loadEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(recordingsList.request.match),
    withLatestFrom(state$),
    map(([, state]) =>
      state.header.searchValue ? recordingsList.bySearchRequest(state.header.searchValue) : recordingsList.allRequest(),
    ),
  );

export const bySearchEpic: AppEpic = (action$, state$, { api, firebase }) =>
  action$.pipe(
    filter(recordingsList.bySearchRequest.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.auth.user),
    map(([action, state]) => [action.payload, state.auth.user!.uid]),
    switchMap(([searchValue, uid]) =>
      api.search.recordingIds(searchValue).pipe(
        switchMap((recordingIds) => firebase.getRecordingsByIds(uid, recordingIds)),
        map(recordingsList.success),
        catchError(
          handleAjaxError(action$, (e) =>
            recordingsList.failure(e instanceof Error ? e.message : 'Error during searched recordings fetch'),
          ),
        ),
      ),
    ),
  );

export const allEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(recordingsList.allRequest.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.auth.user),
    map(([, state]) => state.auth.user!.uid),
    switchMap((uid) =>
      firebase
        .getAllRecordings(uid)
        .pipe(
          map(recordingsList.success),
          catchError(
            handleAjaxError(action$, (e) =>
              recordingsList.failure(e instanceof Error ? e.message : 'Error during all recordings fetch'),
            ),
          ),
        ),
    ),
  );

export const deleteRecordingEpic: AppEpic = (action$, state$, { firebase, api }) =>
  action$.pipe(
    filter(recordingsList.deleteById.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.auth.user),
    map(([action, state]) => [action.payload, state.auth.user!.uid]),
    switchMap(([recordingId, uid]) => firebase.deleteFile(uid, recordingId).pipe(map(() => recordingId))),
    switchMap((recordingId) => api.search.deleteRecording(recordingId).pipe(catchError(handleAjaxError(action$)))),
    map(() => recordingsList.request()),
  );
