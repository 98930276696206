import { AppEpic } from '../../store/root-epic';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { configurationInfo } from './ConfigurationInfo.slice';
import { handleAjaxError } from '../../services/api.service';

export const ConfigurationInfoEpic: AppEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(configurationInfo.request.match),
    switchMap(() =>
      api.configuration
        .full()
        .pipe(
          map(configurationInfo.success),
          catchError(
            handleAjaxError(action$, (e) =>
              configurationInfo.failure(e instanceof Error ? e.message : 'Error during configuration fetch'),
            ),
          ),
        ),
    ),
  );
