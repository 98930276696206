import { combineEpics, Epic } from 'redux-observable';
import { AnyAction } from '@reduxjs/toolkit';
import { AppState } from './root-reducer';
import { AppServices } from '../services';
import * as authEpics from '../components/reusable/epics/auth.epics';
import * as signUpEpics from '../components/SignUp/SignUp.epics';
import * as signInEpics from '../components/SignIn/SignIn.epics';
import * as headerEpics from '../components/Header/Header.epics';
import * as configurationInfoEpics from '../components/ConfigurationInfo/ConfigurationInfo.epics';
import * as homeEpics from '../components/Home/Home.epics';
import * as createRecordingEpics from '../components/CreateRecording/CreateRecording.epics';
import * as createRecordingBodyEpics from '../components/CreateRecording/CreateRecordingBody/CreateRecordingBody.epics';
import * as audioRecorderEpics from '../components/CreateRecording/AudioRecorder/AudioRecorder.epics';

const rootEpic = combineEpics(
  ...Object.values(authEpics),
  ...Object.values(signUpEpics),
  ...Object.values(signInEpics),
  ...Object.values(headerEpics),
  ...Object.values(configurationInfoEpics),
  ...Object.values(homeEpics),
  ...Object.values(createRecordingEpics),
  ...Object.values(createRecordingBodyEpics),
  ...Object.values(audioRecorderEpics),
);

export type AppEpic = Epic<AnyAction, AnyAction, AppState, AppServices>;

export default rootEpic;
