import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

type SignInState = {
  error?: string;
};

const initialState: SignInState = {
  error: undefined,
};

const signInFailure = (state: Draft<SignInState>, action: PayloadAction<string>) => {
  state.error = action.payload;
};

const signInSlice = createSlice({
  name: 'sign-in',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    withEmailPasswordRequest: (state, _: PayloadAction<{ email: string; password: string }>) => {
      state.error = undefined;
    },
    withEmailPasswordFailure: signInFailure,
    withGoogleRequest: (state) => {
      state.error = undefined;
    },
    withGoogleFailure: signInFailure,
  },
});

export const signIn = signInSlice.actions;

export default signInSlice.reducer;
