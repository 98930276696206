/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AppEpic } from '../../store/root-epic';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { recording } from './Recording.slice';
import { recordingsList } from '../RecordingsList/RecordingsList.slice';

export const selectEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(recording.select.match),
    withLatestFrom(state$),
    filter(([, state]) => !state.home.recording.data?.audioUrl),
    map(([action]) => action.payload),
    tap(({ history }) => history.push('/home/recordings')),
    map(({ recording: { id } }) => recording.audioUrlRequest(id)),
  );

export const audioUrlEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(recording.audioUrlRequest.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.auth.user),
    map(([action, state]) => [action.payload, state.auth.user!.uid]),
    switchMap(([recordingId, uid]) => firebase.getRecordingAudioUrl(uid, recordingId)),
    map((url) => recording.audioUrlSuccess(url)),
    catchError((e) =>
      of(recording.audioUrlFailure(e instanceof Error ? e.message : 'Error while setting current recording')),
    ),
  );

export const audioUrlFailureEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(recording.audioUrlFailure.match),
    map(() => recording.deselect()),
  );

export const deleteRecordingEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(recordingsList.deleteById.match),
    withLatestFrom(state$),
    filter(([action, state]) => action.payload === state.home.recording.data?.id),
    map(() => recording.deselect()),
  );

export const reloadRecordingsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => recordingsList.allRequest.match(action) || recordingsList.bySearchRequest.match(action)),
    withLatestFrom(state$),
    filter(([, state]) => !!state.home.recording.data),
    map(() => recording.deselect()),
  );
