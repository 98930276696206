import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import { ajax } from 'rxjs/internal-compatibility';
import { map, switchMap } from 'rxjs/operators';
import { flow } from 'fp-ts/function';
import { formatValidationErrors } from 'io-ts-reporters';
import { Observable, of, throwError } from 'rxjs';

const baseConfiguration = t.type({
  version: t.union([t.undefined, t.string]),
  environmentType: t.union([t.undefined, t.string]),
});

const clientConfiguration = t.intersection([
  baseConfiguration,
  t.type({
    apiUrl: t.union([t.undefined, t.string]),
  }),
]);

const serverConfiguration = t.intersection([
  baseConfiguration,
  t.type({
    elasticNodeUri: t.union([t.undefined, t.string]),
  }),
]);

export type ClientConfiguration = t.TypeOf<typeof clientConfiguration>;
export type ServerConfiguration = t.TypeOf<typeof serverConfiguration>;

export const getServerConfiguration = (
  baseUrl: string,
  headers: Record<string, string>,
): Observable<ServerConfiguration> =>
  ajax.get(`${baseUrl}/configuration`, headers).pipe(
    map(({ response }) => serverConfiguration.decode(response)),
    switchMap(
      flow(
        E.mapLeft(formatValidationErrors),
        E.mapLeft((errors) => new Error(errors.join(', '))),
        E.fold(throwError, (configuration) => of(configuration)),
      ),
    ),
  );

export const getClientConfiguration = (): ClientConfiguration => ({
  apiUrl: process.env.REACT_APP_API_URL,
  version: process.env.REACT_APP_FullSemVer,
  environmentType: process.env.NODE_ENV,
});

export const getFullConfiguration = (
  baseUrl: string,
  headers: Record<string, string>,
): Observable<{ client: ClientConfiguration; server: ServerConfiguration }> =>
  getServerConfiguration(baseUrl, headers).pipe(
    map((server) => ({
      server: server,
      client: getClientConfiguration(),
    })),
  );
