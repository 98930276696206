import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WordData = {
  streamId?: number;
  word: string;
  startTime?: number;
  endTime?: number;
  speakerTag: number;
};

export type TranslationData = {
  streamId: number;
  translation: string;
};

type CreateRecordingBodyState = {
  finalizedWords: Array<WordData>;
  ongoingTranscript: string;
  finalizedTranslations: Array<TranslationData>;
  ongoingTranslation: string;
};

const initialState: CreateRecordingBodyState = {
  finalizedWords: [],
  ongoingTranscript: '',
  finalizedTranslations: [],
  ongoingTranslation: '',
};

const createRecordingBodySlice = createSlice({
  name: 'create-recording-body',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    addFinalizedWords: (state, action: PayloadAction<{ streamId: number; finalizedWords: Array<WordData> }>) => {
      state.finalizedWords = action.payload.finalizedWords
        .concat(state.finalizedWords.filter((data) => data.streamId !== action.payload.streamId))
        .sort((a, b) => (a.startTime ?? 0) - (b.startTime ?? 0));
    },
    setOngoingTranscript: (state, action: PayloadAction<string>) => {
      state.ongoingTranscript = action.payload;
    },
    addFinalizedTranslation: (
      state,
      action: PayloadAction<{ streamId: number; finalizedTranslation: TranslationData }>,
    ) => {
      state.finalizedTranslations = [action.payload.finalizedTranslation]
        .concat(state.finalizedTranslations.filter((data) => data.streamId !== action.payload.streamId))
        .sort((a, b) => a.streamId - b.streamId);
    },
    setOngoingTranslation: (state, action: PayloadAction<string>) => {
      state.ongoingTranslation = action.payload;
    },
  },
});

export const createRecordingBody = createRecordingBodySlice.actions;

export default createRecordingBodySlice.reducer;
