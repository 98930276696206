import { AppEpic } from '../../../store/root-epic';
import { concatMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { createRecording } from '../CreateRecording.slice';
import { of } from 'rxjs';
import { createRecordingBody } from './CreateRecordingBody.slice';

export const TranscriptEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(createRecording.processSpeechEvent.match),
    filter((action) => action.payload.event.type === 'transcript'),
    map((action) => action.payload.event.data),
    filter((data: any) => !!data?.transcript),
    concatMap((data) => {
      const words = data.words;
      const streamId = data.streamId ?? 0;

      if (data.isFinal && Array.isArray(words)) {
        return of(
          createRecordingBody.setOngoingTranscript(''),
          createRecordingBody.addFinalizedWords({
            streamId: streamId,
            finalizedWords: words.map((data) => ({
              streamId: streamId,
              word: data.word,
              speakerTag: data.speakerTag,
              startTime: data.startTime,
              endTime: data.endTime,
            })),
          }),
        );
      }

      return of(createRecordingBody.setOngoingTranscript(data.transcript ?? ''));
    }),
  );

export const TranslateEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(createRecording.processSpeechEvent.match),
    filter((action) => action.payload.event.type === 'translate'),
    map((action) => action.payload.event.data),
    filter((data: any) => !!data),
    withLatestFrom(state$),
    filter(
      ([, state]) =>
        state.createRecording.configuration.translateLanguage !== state.createRecording.configuration.meetingLanguage,
    ),
    concatMap(([data]) => {
      const streamId = data.streamId ?? 0;

      if (data.translation && data.isFinal) {
        return of(
          createRecordingBody.setOngoingTranslation(''),
          createRecordingBody.addFinalizedTranslation({
            streamId,
            finalizedTranslation: {
              streamId: streamId,
              translation: data.translation,
            },
          }),
        );
      }

      return of(createRecordingBody.setOngoingTranslation(data.translation ?? ''));
    }),
  );
