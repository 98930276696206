import moment from 'moment';

export const getEventTime = (event) => {
  if (event.start.dateTime) {
    const dayOfWeek = moment(event.start.dateTime).format('dddd DD MMMM');
    const startOfEvent = moment(event.start.dateTime).format('HH:MM');
    const endOfEvent = moment(event.end.dateTime).format('HH:MM');
    return dayOfWeek + ' ' + startOfEvent + ' - ' + endOfEvent;
  }
  return moment(event.start.date).format('dddd DD MMMM');
};

export const getCorrectDateForCard = (date) => {
  const momentDate = moment(date);
  if (date && momentDate.isValid(date)) {
    return date;
  }
  if (typeof date === 'object' && date.seconds) {
    const unixMoment = moment.unix(date.seconds);
    return unixMoment.isValid() && moment(unixMoment).format('YYYY/MM/DD HH:MM');
  }
  return null;
};
