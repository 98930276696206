import { AnyAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer, { AppState } from './root-reducer';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './root-epic';
import appServices from '../services';
import { AppServices } from '../services';

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState, AppServices>({
  dependencies: appServices,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    epicMiddleware,
  ],
});

epicMiddleware.run(rootEpic);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./root-reducer', async () => {
    const rootReducerImport = await import('./root-reducer');
    const newRootReducer = rootReducerImport.default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;
