import { AppEpic } from '../../store/root-epic';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { from, of, throwError } from 'rxjs';
import { auth, mapUser } from '../reusable/slices/auth.slice';
import { signIn } from './SignIn.slice';

export const signInWithEmailPasswordEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(signIn.withEmailPasswordRequest.match),
    filter(({ payload: { email, password } }) => !!email && !!password),
    mergeMap(({ payload: { email, password } }) =>
      from(firebase.signInWithEmailPassword(email, password)).pipe(
        switchMap(({ user }) => (user ? of(auth.setUser(mapUser(user))) : throwError('user is null after sign in'))),
        catchError((error) => of(signIn.withEmailPasswordFailure(error.toString()))),
      ),
    ),
  );

export const signInWithGoogleEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(signIn.withGoogleRequest.match),
    mergeMap(() =>
      from(firebase.signInWithGoogle()).pipe(
        switchMap(({ user }) => (user ? of(auth.setUser(mapUser(user))) : throwError('user is null after sign in'))),
        catchError((error) => of(signIn.withGoogleFailure(error.toString()))),
      ),
    ),
  );
