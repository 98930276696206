import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DeleteRecordingModalState = {
  recordingId?: string;
};

const initialState: DeleteRecordingModalState = {
  recordingId: undefined,
};

const deleteRecordingModalSlice = createSlice({
  name: 'delete-recording-modal',
  initialState: initialState,
  reducers: {
    prompt: (state, action: PayloadAction<string>) => {
      state.recordingId = action.payload;
    },
    close: (state) => {
      state.recordingId = undefined;
    },
  },
});

export const deleteRecordingModal = deleteRecordingModalSlice.actions;

export default deleteRecordingModalSlice.reducer;
