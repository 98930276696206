import { ajax, AjaxResponse } from 'rxjs/internal-compatibility';
import { Observable } from 'rxjs';

export const searchRecordingIds = (
  baseUrl: string,
  headers: Record<string, string>,
  search: string,
): Observable<Array<string>> => ajax.getJSON(`${baseUrl}/search/search?query=${search}`, headers);

export const deleteRecording = (
  baseUrl: string,
  headers: Record<string, string>,
  recordingId: string,
): Observable<AjaxResponse> =>
  ajax({
    url: `${baseUrl}/search/delete-entity?entityId=${recordingId}`,
    method: 'DELETE',
    headers: headers,
  });

export const setTranscriptToElastic = (
  baseUrl: string,
  headers: Record<string, string>,
  transcript: string,
  id: string,
): Observable<AjaxResponse> =>
  ajax({
    url: `${baseUrl}/search/add-entity`,
    method: 'POST',
    headers: headers,
    body: {
      value: transcript,
      id: id,
    },
  });
