import { combineReducers } from '@reduxjs/toolkit';
import recordingsListReducer from '../RecordingsList/RecordingsList.slice';
import deleteRecordingModalReducer from '../DeleteRecordingModal/DeleteRecordingModal.slice';
import recordingReducer from '../Recording/Recording.slice';
import { WordData } from '../CreateRecording/CreateRecordingBody/CreateRecordingBody.slice';
import firebase from 'firebase/app';

export type RecordingInfo = {
  id: string;
  title?: string;
  date?: firebase.firestore.Timestamp;
  words?: Array<WordData>;
  translate?: string;
};

const homeReducer = combineReducers({
  recordingsList: recordingsListReducer,
  deleteRecordingModal: deleteRecordingModalReducer,
  recording: recordingReducer,
});

export default homeReducer;
