export const FULL_MEMORY = 256;
export const englishUnitedStates = 'en-US';
export const englishUnitedKingdom = 'en-GB';
export const russian = 'ru-RU';

export const languageOptions = [
  { value: englishUnitedStates, text: 'English (United States)' },
  { value: englishUnitedKingdom, text: 'English (United Kingdom)' },
  { value: russian, text: 'Russian' },
];

export const speakersOptions = [
  { value: '1', text: 'One Speaker' },
  { value: '2', text: 'Two Speakers' },
  { value: '3', text: 'Three Speakers' },
  { value: '4', text: 'Four Speakers' },
  { value: '5', text: 'Five Speakers' },
  { value: '6', text: 'Six Speakers' },
];
