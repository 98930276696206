import { Observable, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';

type SpeechEventType = 'transcript' | 'translate' | 'error';

export type SpeechEvent = { type: SpeechEventType; data: unknown };

export const openSpeechSocket = (
  token: string,
  meetingLanguage: string,
  translateLanguage: string,
  userUid: string,
  speechContext: string,
  speakerCount: string,
): [Observable<{ event: SpeechEvent; socket: Socket }>, Socket] => {
  const subject = new Subject<{ event: SpeechEvent; socket: Socket }>();

  const socket = io(process.env.REACT_APP_API_URL ?? '', {
    auth: {
      token: token,
    },
    query: {
      language: meetingLanguage,
      speechContext: speechContext,
      targetLanguage: translateLanguage,
      speakersCount: speakerCount,
    },
  });

  socket.on('connect_error', (e) => {
    subject.error({ socket: socket, error: e });
  });

  socket.on(`transcript-${userUid}`, (transcript: unknown) =>
    subject.next({ event: { type: 'transcript', data: transcript }, socket: socket }),
  );
  socket.on(`translate-${userUid}`, (translate: unknown) =>
    subject.next({ event: { type: 'translate', data: translate }, socket: socket }),
  );

  return [subject, socket];
};
