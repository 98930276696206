import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase';
import { pipe } from 'fp-ts/pipeable';
import * as D from 'io-ts/Decoder';
import * as O from 'fp-ts/Option';
import { flow } from 'fp-ts/function';

const appUser = D.type({
  uid: D.string,
  displayName: D.nullable(D.string),
  email: D.nullable(D.string),
  emailVerified: D.boolean,
});

export type AppUser = D.TypeOf<typeof appUser>;

type AuthState = {
  user?: AppUser;
  accessToken?: string;
};

export const mapUser = (user: User): AppUser => ({
  uid: user.uid,
  displayName: user.displayName,
  email: user.email,
  emailVerified: user.emailVerified,
});

const user = pipe(
  localStorage.getItem('user'),
  O.fromNullable,
  O.bind('parsedUser', flow(JSON.parse, O.fromNullable)),
  O.bind('decodedUser', ({ parsedUser }) => pipe(parsedUser, appUser.decode, O.fromEither)),
  O.map(({ decodedUser }) => decodedUser),
  O.toUndefined,
);

const initialState: AuthState = {
  user: user,
  accessToken: localStorage.getItem('access-token') ?? undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AppUser>) => {
      state.user = action.payload;
    },
    clear: (state) => {
      state.user = undefined;
      state.accessToken = undefined;
    },
    refreshAccessTokenSuccess: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
  },
});

export const auth = {
  ...authSlice.actions,
  refreshAccessTokenRequest: createAction('auth/refreshAccessTokenRequest'),
};

export default authSlice.reducer;
