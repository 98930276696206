import { AppEpic } from '../../../store/root-epic';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { createRecording } from '../CreateRecording.slice';
import { Observable, of } from 'rxjs';
import { audioRecorder } from './AudioRecorder.slice';

export const stopRecordingEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(audioRecorder.stopRecordingRequest.match),
    mergeMap(({ payload: recorder }) =>
      new Observable<{ url: string; blob: Blob }>((subscriber) => {
        try {
          recorder.stopRecording(() => {
            subscriber.next({ url: recorder.toURL(), blob: recorder.getBlob() });
            subscriber.complete();
            recorder.destroy();
          });
        } catch (e) {
          subscriber.error(e);
          recorder.destroy();
        }
      }).pipe(
        map(audioRecorder.stopRecordingSuccess),
        catchError((e) => of(audioRecorder.stopRecordingFailure(e.message ?? ''))),
        mergeMap((action) => of(action, createRecording.closeSpeechSocket())),
      ),
    ),
  );
