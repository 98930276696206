/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AppEpic } from '../../store/root-epic';
import {
  catchError,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  take,
  takeLast,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { createRecording } from './CreateRecording.slice';
import { defer, EMPTY, merge, of } from 'rxjs';
import { handleAjaxError, refreshAccessTokenAndRetry } from '../../services/api.service';
import { audioRecorder } from './AudioRecorder/AudioRecorder.slice';
import { createRecordingBody, WordData } from './CreateRecordingBody/CreateRecordingBody.slice';
import { events } from '../Events/Events.slice';
import { getEventTime } from '../../utils/events';
import { AppState } from '../../store/root-reducer';
import { recordingsList } from '../RecordingsList/RecordingsList.slice';

export const occupiedMemoryEpic: AppEpic = (action$, state$, { firebase }) =>
  action$.pipe(
    filter(createRecording.refreshOccupiedMemoryRequest.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.auth.user),
    map(([, state]) => state.auth.user!.uid),
    switchMap((uid) =>
      firebase.getUsedMemory(uid).pipe(
        map(createRecording.refreshOccupiedMemorySuccess),
        catchError((e) => of(createRecording.refreshOccupiedMemoryFailure(e))),
      ),
    ),
  );

export const speechSocketEpic: AppEpic = (action$, state$, { socket: socketService }) =>
  action$.pipe(
    filter(createRecording.openSpeechSocket.match),
    switchMap(() =>
      defer(() => {
        const state = state$.value;
        const [speechEvent$, socket] = socketService.openSpeechSocket(
          state.auth.accessToken ?? '',
          state.createRecording.configuration.meetingLanguage,
          state.createRecording.configuration.translateLanguage,
          state.auth.user?.uid ?? '',
          state.createRecording.root.title,
          state.createRecording.configuration.speakerCount,
        );

        return merge(
          of(createRecording.speechSocketReady(socket)),
          speechEvent$.pipe(map(createRecording.processSpeechEvent)),
        );
      }).pipe(
        catchError(({ socket, error }, source) => {
          if (
            error instanceof Error &&
            (error.message === 'There is no token or it is empty' || error.message === 'Authentication error')
          ) {
            return merge(of(createRecording.closeSpeechSocket()), refreshAccessTokenAndRetry(action$, source));
          }

          console.error(error);
          return of(
            createRecording.processSpeechEvent({
              event: { type: 'error', data: error },
              socket: socket,
            }),
            createRecording.closeSpeechSocket(),
          );
        }),
      ),
    ),
  );

export const speechSocketEmitEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => createRecording.speechSocketReady.match(action) || createRecording.closeSpeechSocket.match(action),
    ),
    distinctUntilChanged((a, b) => a.type === b.type),
    switchMap(({ payload: socket, type }) =>
      type === createRecording.speechSocketReady.type
        ? action$.pipe(
            filter(audioRecorder.processBlobPart.match),
            tap(({ payload: blobPart }) => socket.emit('stream', blobPart)),
            ignoreElements(),
          )
        : EMPTY,
    ),
  );

export const closeSpeechSocketEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(createRecording.speechSocketReady.match),
    switchMap(({ payload: socket }) =>
      action$.pipe(
        filter(createRecording.closeSpeechSocket.match),
        tap(() => socket.emit('stop-stream')),
        take(1),
      ),
    ),
    ignoreElements(),
  );

export const resetEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(createRecording.reset.match),
    switchMap(() =>
      of(createRecordingBody.reset(), audioRecorder.reset(), createRecording.refreshOccupiedMemoryRequest()),
    ),
  );

export const titleEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(events.setRecordingEvent.match),
    filter((action) => !!action.payload),
    map(({ payload }) => createRecording.setTitle(`${payload.summary} ${getEventTime(payload)}`)),
  );

export const saveEpic: AppEpic = (action$, state$, { firebase, api }) =>
  action$.pipe(
    filter(createRecording.saveRequest.match),
    withLatestFrom(state$),
    filter(
      ([, state]) =>
        !!state.createRecording.recorder.blobUrl && !!state.auth.user?.uid && !!state.createRecording.recorder.blob,
    ),
    mergeMap(([, state]) =>
      firebase
        .setFileToStorage(state.auth.user!.uid, state.createRecording.recorder.blob!)
        .pipe(map((key): [string, AppState] => [key, state])),
    ),
    mergeMap(([key, state]) => {
      const finalWords: Array<WordData> = state.createRecording.body.ongoingTranscript
        ? [
            ...state.createRecording.body.finalizedWords,
            {
              word: state.createRecording.body.ongoingTranscript,
              speakerTag: -1,
            },
          ]
        : state.createRecording.body.finalizedWords;

      const finalTranslation: string =
        state.createRecording.body.finalizedTranslations.join(' ') + state.createRecording.body.ongoingTranslation;

      const firebaseData$ = firebase.setDataToFirestoreDB(state.auth.user!.uid, key, {
        title: state.createRecording.root.title,
        words: finalWords,
        languages: [state.createRecording.configuration.meetingLanguage],
        user: state.auth.user!.email,
        translate: finalTranslation,
      });

      if (!finalWords.some((w) => w)) {
        return firebaseData$;
      }

      const elastic$ = api.search
        .setTranscriptToElastic(`${finalWords.map((w) => w.word).join(' ')} ${finalTranslation}`, key)
        .pipe(catchError(handleAjaxError(action$)));

      return merge(elastic$, firebaseData$).pipe(takeLast(1));
    }),
    map(() => createRecording.saveSuccess()),
    catchError((e) => of(createRecording.saveFailure(e.message ?? ''))),
    mergeMap((action) => of(action, createRecording.reset(), events.clearRecordingEvent(), recordingsList.request())),
  );
