import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SignUpState = {
  error?: string;
};

const initialState: SignUpState = {
  error: undefined,
};

const signUpSlice = createSlice({
  name: 'sign-up',
  initialState: initialState,
  reducers: {
    request: (state, _: PayloadAction<{ email: string; password: string }>) => {
      state.error = undefined;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    reset: () => initialState,
  },
});

export const signUp = signUpSlice.actions;

export default signUpSlice.reducer;
