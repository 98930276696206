import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import RecordRTC from 'recordrtc';

type AudioRecorderState = {
  blobUrl?: string;
  blob?: Blob;
  isStopping: boolean;
  error?: string;
};

const initialState: AudioRecorderState = {
  blobUrl: undefined,
  blob: undefined,
  isStopping: false,
  error: undefined,
};

const audioRecorderSlice = createSlice({
  name: 'audio-recorder',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    stopRecordingRequest: (state, _: PayloadAction<RecordRTC>) => {
      state.isStopping = true;
      state.error = undefined;
    },
    stopRecordingFailure: (state, action: PayloadAction<string>) => {
      state.isStopping = false;
      state.error = action.payload;
      state.blobUrl = undefined;
      state.blob = undefined;
    },
    stopRecordingSuccess: (state, action: PayloadAction<{ url: string; blob: Blob }>) => {
      state.isStopping = false;
      state.error = undefined;
      state.blobUrl = action.payload.url;
      state.blob = action.payload.blob;
    },
    setBlobUrlTimestamp: (state, action: PayloadAction<number>) => {
      if (!state.blobUrl) {
        return;
      }

      const blobWithoutTimeStamp = state.blobUrl.split('#t=')[0];
      state.blobUrl = blobWithoutTimeStamp + '#t=' + action.payload;
    },
  },
});

export const audioRecorder = {
  processBlobPart: createAction<ArrayBuffer>('audio-recorder/processBlobPart'),
  ...audioRecorderSlice.actions,
};

export default audioRecorderSlice.reducer;
