import * as t from 'io-ts';
import { englishUnitedKingdom, englishUnitedStates, russian } from '../../../utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const LanguageCode = t.union([
  t.literal(englishUnitedStates),
  t.literal(englishUnitedKingdom),
  t.literal(russian),
]);

export const SpeakerCount = t.union([
  t.literal('1'),
  t.literal('2'),
  t.literal('3'),
  t.literal('4'),
  t.literal('5'),
  t.literal('6'),
]);

type LanguageCode = t.TypeOf<typeof LanguageCode>;
type SpeakerCount = t.TypeOf<typeof SpeakerCount>;

type CreateRecordingConfigurationState = {
  speakerCount: SpeakerCount;
  meetingLanguage: LanguageCode;
  translateLanguage: LanguageCode;
};

const initialState: CreateRecordingConfigurationState = {
  speakerCount: '2',
  meetingLanguage: englishUnitedStates,
  translateLanguage: englishUnitedStates,
};

const createRecordingConfigurationSlice = createSlice({
  name: 'create-recording-configuration',
  initialState: initialState,
  reducers: {
    setSpeakerCount: (state, action: PayloadAction<SpeakerCount>) => {
      state.speakerCount = action.payload;
    },
    setMeetingLanguage: (state, action: PayloadAction<LanguageCode>) => {
      state.meetingLanguage = action.payload;
    },
    setTranslateLanguage: (state, action: PayloadAction<LanguageCode>) => {
      state.translateLanguage = action.payload;
    },
  },
});

export const createRecordingConfiguration = createRecordingConfigurationSlice.actions;

export default createRecordingConfigurationSlice.reducer;
